import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';


import { lazyload } from './inits/lazyload';
import lineAnimations from './animations/line-animations';
import lineAnimationsMoving from './animations/line-animations-moving';
import strokeAnimations from './animations/stroke-animation';
import marqueeInit from './inits/marquee';
import sliderPaginationInit from './inits/slider-pagination';
import sliderGalleryInit from './inits/slider-gallery';
import newsSliderInit from './inits/news-slider';
import storiesCollapseInit from './inits/stories-collapse';
import headerThemeObserverInit from './inits/header-theme-observer';
import inputFocusInit from './inits/inputs-focus';
import hideHeaderOnScrollInit from './inits/hide-header-on-scroll';
import revealInit from './inits/reveal';
import menuCollapsesInit from './inits/menu-collapses';
import filterCollapseInit from './inits/filter-collapse';
import youtubePlayerInit from './inits/youtube-video';
import initSelects from './inits/selects';
import ajaxFormsInit from './inits/ajax-forms';
import axiosFormsInit from './inits/forms';
import initPhoneMask from './inits/masks';
import ctaFormInit from './inits/cta-form';
import initFormInputs from './inits/form-inputs';
import initFileInputs from './inits/file-inputs';
import langSelects from './inits/lang-change';

import navChangeHandlerInit from './inits/nav-change-handler';


import { calculateScrollbarWidth } from '../modules/calculate-scrollbar-width';

document.documentElement.classList.add('js-ready');

applyPolyfills().then(() => {
    calculateScrollbarWidth();
    lazyload.update();
    lineAnimations();
    // ajaxFormsInit();
    axiosFormsInit();
    lineAnimationsMoving();
    strokeAnimations();
    marqueeInit();
    initPhoneMask();
    sliderPaginationInit();
    sliderGalleryInit();
    newsSliderInit();
    storiesCollapseInit();
    headerThemeObserverInit();
    inputFocusInit();
    revealInit();
    hideHeaderOnScrollInit();
    menuCollapsesInit();
    filterCollapseInit();
    navChangeHandlerInit();
    youtubePlayerInit();
    initSelects();
    ctaFormInit();
    initFormInputs();
    initFileInputs();
    langSelects();
    const toTop = document.querySelector<HTMLElement>(".js-to-top");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            toTop?.classList.add('is-in')
        } else {
            toTop?.classList.remove('is-in')
        }
    }

    toTop?.addEventListener('click', topFunction)

    // When the user clicks on the button, scroll to the top of the document
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    scrollFunction();
});

// Always at the end
// module.hot?.accept();
